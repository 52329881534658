const formStyles = {
  helper: {
    position: 'absolute',
    bottom: '5px',
    marginLeft: '0',
    marginRight: '0',
    lineHeight: '13px'
  },
  input: {
    pb: 4
  },
  form: {
    maxWidth: '530px',
    margin: '0 auto',
  },
  error: {},
  formError: {
    color: '#d32f2f',
    fontSize: '14px',
  },
  subTitle: {
    mb: '1rem',
    mt: '1.5rem'
  },
  extraButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    right: '-75px'
  }
};

export default formStyles;
