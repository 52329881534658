import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import EditUserInformation from 'pages/EditUserInformation';
import { setUserData, getUserByIdAndRole } from 'feature/auth/authSlice';
import ProtectedRoute from 'components/utils/router/ProtectedRoute';
import { ConsultantAccountDeactivatedRoute, UserAccountDeletedRoute } from 'components/utils/router/DeletedRoute';
import RestorePassword from 'components/forms/RestorePassword';
import RequestDetails from 'pages/RequestDetails';
import Cookies from 'js-cookie';
import Login from 'pages/Login';
import Registration from 'pages/RegistrationPage';
import UserList from 'pages/UserList';
import ClientConsultantList from 'pages/ClientConsultantList';
import RequestList from 'pages/RequestList';
import EditConsultant from 'pages/EditConsultant';
import EditClient from 'pages/EditClient';
import ExpertList from 'pages/ExpertList';
import ExpertInfo from 'pages/ExpertInfo';
import ConfirmExpert from 'pages/ConfirmExpert';
import DeclineExpert from 'pages/DeclineExpert';
import SuccessExpertAction from 'pages/SuccessExpertAction';
import { BackToConfirmExpertBtn } from 'pages/components/BackToConfirmExpertBtn';
import ClientList from 'pages/Consultant/ClientList';
import ClientInfo from 'pages/Consultant/ClientInfo';
import ConsultantProfile from 'pages/Consultant/ConsultantProfile';
import ClientProfile from 'pages/ClientProfile';
import Landing from 'pages/LandingPage';
import { getUserFromAuthStore, getAllAuthUserStore } from 'feature/auth/authSelectors';
import Notifier from 'components/Notifier';
import { useAppDispatch, useAppSelector } from 'hooks';

import { ROUTES } from './configs/routes';
import './index.css';

function App() {
  const dispatch = useAppDispatch();
  const { id, role } = useAppSelector(getUserFromAuthStore);
  const { isLoading } = useAppSelector(getAllAuthUserStore);
  const sessionUserId = Cookies.get('id') as string;
  const sessionRole = Cookies.get('role') as string;

  const isAccessAllowed = Boolean(id);
  const isAdmin = /Admin/i.test(role);
  const isCoordinator = /Coordinator/i.test(role);
  const isClient = /Client/i.test(role);
  const isConsultant = /Consultant/i.test(role);

  const loggedAdmin = isAccessAllowed && isAdmin;
  const loggedCoordinator = isAccessAllowed && isCoordinator;
  const loggedClient = isAccessAllowed && isClient;
  const loggedConsultant = isAccessAllowed && isConsultant;

  useEffect(() => {
    if (sessionUserId && sessionRole) {
      dispatch(setUserData());
    }
  }, [sessionUserId, sessionRole]);

  useEffect(() => {
    // Case when user reloads page and we lost user data from Store
    if ((loggedAdmin || loggedCoordinator || loggedClient || loggedConsultant) && (!id || !role)) {
      dispatch(
        getUserByIdAndRole({
          id: sessionUserId,
          role: sessionRole,
        })
      );
    }
  }, [dispatch, id, role]);
  return (
    <>
      <Notifier />
      <Routes>
        {/* TODO: map "replace to" param based on next cases: client, admin/coordinator, not logged */}
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Landing />} />
        {UserAccountDeletedRoute}
        {ConsultantAccountDeactivatedRoute}
        <Route
          path="/login"
          element={<Login isLoading={isLoading} isClient={isClient} isLoggedAdmin={loggedAdmin || loggedCoordinator} isConsultant={isConsultant} />}
        />
        <Route path="/registration" element={<Registration />} />
        <Route path="/restore-password" element={<RestorePassword />} />
        {/* Protected routes for admin and coordinator */}
        <Route
          element={
            <ProtectedRoute isLoading={isLoading} isAllowed={loggedAdmin || loggedCoordinator} />
          }
        >
          <Route path="admin/clients" element={<ClientConsultantList />} />
          <Route path="admin/users" element={<UserList />} />
          {/* TODO: invite users feature is not fully functional, see https://jira.softserveinc.com/browse/CRSV-608 */}
          {/* <Route path="admin/users/invite" element={<InviteUser />} /> */}
          <Route
            path="admin/users/edit-user/:userRole/:userId"
            element={<EditUserInformation />}
          />
          <Route
            path="admin/users/edit-user/Consultant/:userId"
            element={<ConsultantProfile isAdmin={loggedAdmin || loggedCoordinator} />}
          />
          <Route
            path="admin/users/edit-user/Client/:userId"
            element={<ClientProfile isAdmin={loggedAdmin || loggedCoordinator} />}
          />
          <Route
            path="admin/clients/consultant/:consultantId"
            element={<EditConsultant />}
          />
          <Route
            path="admin/clients/:clientId"
            element={<EditClient />}
          />
          <Route path="admin/requests" element={<RequestList />} />
          <Route
            path="admin/requests/:id"
            element={<RequestDetails />}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute
              isAllowed={loggedClient}
              isLoading={isLoading}
            />
          }
        >
          <Route path={ROUTES.expertList} element={<ExpertList />} />
          <Route path={ROUTES.expertInfo} element={<ExpertInfo />} />
          <Route path={ROUTES.editUserProfile} element={<ClientProfile />}>
            <Route path=":consultantId" element={<BackToConfirmExpertBtn />} />
          </Route>
          <Route path={ROUTES.confirmExpert} element={<ConfirmExpert />} />
          <Route path={ROUTES.declineExpert} element={<DeclineExpert />} />

          <Route path={ROUTES.successExpertAction} element={<SuccessExpertAction />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              isAllowed={loggedConsultant}
              isLoading={isLoading}
            />
          }
        >
          <Route path={ROUTES.clientList} element={<ClientList />} />
          <Route path={ROUTES.clientInfo} element={<ClientInfo />} />
          <Route path={ROUTES.consultantProfile} element={<ConsultantProfile />} />
        </Route>

        <Route path="*" element={<div>Not found</div>} />
      </Routes>
    </>
  );
}

export default App;
