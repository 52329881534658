import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { PatternFormat } from 'react-number-format';
import { getPhoneFormatByIsoCode } from 'utils';

const PhoneFormatCustom = React.forwardRef((props: any, ref) => {
  const { onChange, value, disabled, ...other } = props;
  const { mask, format } = getPhoneFormatByIsoCode(value.countryOfPhone);
  return (
    <PatternFormat
      {...other}
      value={value.value}
      type="tel"
      disabled={disabled}
      getInputRef={ref}
      allowEmptyFormatting
      onValueChange={(values) => {
        props.onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      mask={mask}
      format={format}
    />
  );
});

PhoneFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default function PhoneInput(props: any) {
  const { countryOfPhone, value, disabled } = props;
  const propsToPass = { ...props };
  delete propsToPass.countryOfPhone;
  return (
    <TextField
      data-testid="phone-number-input"
      autoComplete="off"
      InputProps={{ inputComponent: PhoneFormatCustom, }}
      {...propsToPass}
      value={{ value, countryOfPhone, disabled }}
    />
  );
}
