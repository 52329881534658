import React, { useEffect } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  getUserByIdAndRole,
  updateUserByIdAndRole,
  addUserProfileImage, deleteUserProfileImage
} from 'feature/user/userSlice';
import isEmpty from 'lodash.isempty';
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import isEqual from 'lodash.isequal';
import { VALIDATION_ERROR_MESSAGES } from 'configs/validation/errorMessages';
import LeftTitle from 'components/EditUser/LeftTitle';
import RightTitle from 'components/EditUser/RightTitle';
import { getUserFromUserStore } from 'feature/user/userSelectors';
import PhoneInput from 'components/shared/PhoneInput';
import UserProfileImage from 'components/UserProfileImage';
import { ROLE_URLS } from 'api/urls';
import {
  COMMUNICATION_TYPE_OPTIONS,
  GENDER_TYPE_OPTIONS,
  USER_ROLES,
} from 'configs';
import Spinner from 'components/shared/Spinner';
import EditPasswordForm from 'components/forms/EditPasswordForm';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  EditGridContainerStyles,
  EditFormStyles,
  EditGridItemStyles
} from 'pages/EditUserInformation.styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import formStyles from 'components/forms/Form.styles';
import { createPhoneValidation } from 'pages/RegistrationPage/helper';

const optionalRequiredSchema = (role: any, t: Function) => yup.object().shape(
  {
    gender: yup.lazy((value) => {
      if (value !== undefined) {
        return yup
          .string()
          .required(VALIDATION_ERROR_MESSAGES.gender.required);
      }
      return yup.mixed().notRequired();
    }),
    aboutMe: yup.lazy(() => {
      if (role === 'Consultant') {
        return yup
          .string()
          .required(VALIDATION_ERROR_MESSAGES.aboutMe.required);
      }
      return yup.mixed().notRequired();
    }),
    violenceTypes: yup.lazy((value) => {
      if (value !== undefined && role === 'Client') {
        return yup
          .array().of(yup.string())
          .min(1, VALIDATION_ERROR_MESSAGES.violenceType.required);
      }
      return yup.mixed().notRequired();
    }),
    communicationChannel: yup.lazy((value) => {
      if (value !== undefined) {
        return yup
          .string()
          .required(VALIDATION_ERROR_MESSAGES.communicationChannel.required);
      }
      return yup.mixed().notRequired();
    }),
    email: yup
      .string()
      .email(VALIDATION_ERROR_MESSAGES.email.email),
    firstName: yup
      .string()
      .min(2, VALIDATION_ERROR_MESSAGES.firstName.min)
      .max(32, VALIDATION_ERROR_MESSAGES.firstName.max)
      .required(VALIDATION_ERROR_MESSAGES.firstName.required),
    lastName: yup.string().when(['lastName'], {
      is: () => role === 'Client',
      then: yup
        .string()
        .min(2, VALIDATION_ERROR_MESSAGES.lastName.min)
        .max(32, VALIDATION_ERROR_MESSAGES.lastName.max),
      otherwise: yup
        .string()
        .min(2, VALIDATION_ERROR_MESSAGES.lastName.min)
        .max(32, VALIDATION_ERROR_MESSAGES.lastName.max)
        .required(VALIDATION_ERROR_MESSAGES.lastName.required),
    }),
    specialisations: yup.array().of(yup.string()),
    experience: yup.lazy(() => {
      if (role === 'Consultant') {
        return yup
          .string()
          .required(VALIDATION_ERROR_MESSAGES.experience.required);
      }
      return yup.mixed().notRequired();
    }),
    phone: createPhoneValidation(t),
  },
  [
    ['lastName', 'lastName']
  ]
);

export default function EditUserInformation() {
  const { userRole, userId } = useParams();

  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common']);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const currentUserId = Cookies.get('id');
  const currentUserRole = Cookies.get('role');
  const isYou = userId === currentUserId;
  useEffect(() => {
    if (userId && userRole) {
      dispatch(
        getUserByIdAndRole({
          userId,
          userRole,
        })
      );
    }
  }, [dispatch, userRole, userId]);

  const { user: userData } = useAppSelector(getUserFromUserStore);
  const userStore = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!userStore.addImageLoading && userStore.addImageSuccess) {
      enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
    } else if (!userStore.addImageLoading && userStore.addImageError) {
      enqueueSnackbar(t('commonError'), { variant: 'error' });
    }
  }, [
    userStore.addImageSuccess,
    userStore.addImageError,
    userStore.addImageLoading,
  ]);

  const formik = useFormik({
    initialValues: {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      communicationChannel: userData?.communicationChannel || '',
      gender: userData?.gender || '',
      email: userData?.user.email || '',
      phone: userData?.user.phone || '',
      aboutMe: userData?.aboutMe,
      oldPassword: '',
      newPassword: '',
    },
    enableReinitialize: true,
    validationSchema: optionalRequiredSchema(userRole, t),
    onSubmit: ({
      firstName,
      lastName,
      phone,
      email,
      newPassword,
      gender,
      communicationChannel,
      aboutMe,
    }) => {
      dispatch(
        updateUserByIdAndRole({
          id: userData?.user.id,
          role: userData?.user.role,
          data: {
            firstName,
            lastName: lastName || null,
            communicationChannel: communicationChannel || null,
            aboutMe: aboutMe || null,
            gender: gender || null,
            user: {
              phone,
              email: email || null,
              password: newPassword || null,
            },
          },
        })
      );
    },
  });

  const {
    values,
    initialValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const isUserEditable = isYou ||
    currentUserRole === 'Admin' ||
    ((currentUserRole === 'Coordinator' && userRole !== 'Admin') &&
      (currentUserRole === 'Coordinator' && userRole !== 'Coordinator'));
  return userData?.isLoading || isEmpty(userData) ? (
    <Spinner />
  ) : (
    <Box component="div" flex="0 0 auto">
      <Outlet />
      <Grid
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        container
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        <LeftTitle
          title={
            isYou
              ? t('yourProfile')
              : `${userData.firstName} ${userData.lastName || ''}`
          }
          // @ts-ignore
          userRole={isYou ? '' : USER_ROLES[userData.user.role]}
        />
        <RightTitle
          isYou={isYou}
          id={userData.user.externalId || ''}
          createdAt={isYou ? '' : userData.user.createdAt}
          lastLoginAt={isYou ? '' : userData.user.lastLoginAt}
        />
      </Grid>
      <Grid
        container
        sx={
          EditGridContainerStyles
        }
      >
        {userData.user.role !== 'Admin' &&
        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={2}
          item
          sx={{
            xs: { p: 2 },
            md: { p: 6 },
          }}
        >
          <Box
            m={2}
            mr={{
              sm: 0,
            }}
            sx={EditGridItemStyles}
          >
            <UserProfileImage
              isViewOnly={!isUserEditable}
              // @ts-ignore
              imageUrl={`${ROLE_URLS[userData.user.role]}/${userData.user.id
              }/profile-picture
            `}
              handler={(formData: any) => {
                dispatch(
                  addUserProfileImage({
                    id: userData.user.id,
                    role: userData.user.role,
                    formData,
                  })
                );
              }}
              deletePhoto={() => {
                dispatch(deleteUserProfileImage({
                  id: userData.user.id,
                  role: userData.user.role,
                }));
              }}
            />
          </Box>
        </Grid>}
        <Grid xs={12} sm={8} lg={6} item sx={{ p: 2 }}>
          <form
            style={EditFormStyles}
            onSubmit={handleSubmit}
          >
            <TextField
              disabled={!isUserEditable}
              id="firstName"
              size="small"
              name="firstName"
              label={t('firstName')}
              variant="outlined"
              aria-describedby={t('firstName')}
              helperText={touched.firstName && errors.firstName as string}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && !!errors.firstName}
              sx={{ mb: 2 }}
            />
            <TextField
              disabled={!isUserEditable}
              id="lastName"
              size="small"
              name="lastName"
              label={t('lastName')}
              variant="outlined"
              aria-describedby={t('lastName')}
              sx={{ mb: 2 }}
              helperText={touched.lastName && errors.lastName as string}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && !!errors.lastName}
            />
            {userData?.gender !== undefined && (
              <TextField
                disabled={!isUserEditable}
                select
                id="gender"
                size="small"
                name="gender"
                label={t('gender')}
                variant="outlined"
                aria-describedby={t('gender')}
                sx={{ mb: 2 }}
                helperText={touched.gender && errors.gender as string}
                value={values?.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.gender && !!errors.gender}
              >
                {GENDER_TYPE_OPTIONS.map(({
                  label,
                  value
                }: any) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            )}

            <TextField
              disabled={!isUserEditable}
              id="email"
              size="small"
              name="email"
              label={t('email')}
              variant="outlined"
              aria-describedby={t('email')}
              sx={{ mb: 2 }}
              helperText={touched.email && errors.email as string}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
            />
            <PhoneInput
              disabled={!isYou}
              onBlur={handleBlur}
              FormHelperTextProps={{ style: formStyles.helper }}
              sx={formStyles.input}
              fullWidth
              required
              label={t('phone')}
              value={values.phone}
              id="phone"
              name="phone"
              size="small"
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            {userData?.communicationChannel && (
              <TextField
                disabled={!isUserEditable}
                select
                size="small"
                name="communicationChannel"
                label={t('communicationChannel')}
                variant="outlined"
                aria-describedby={t('communicationChannel')}
                sx={{ mb: 2 }}
                helperText={
                  touched.communicationChannel && errors.communicationChannel as string
                }
                value={values.communicationChannel}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.communicationChannel && !!errors.communicationChannel
                }
              >
                {COMMUNICATION_TYPE_OPTIONS.map(({
                  label,
                  value
                }: any) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {userData?.aboutMe !== undefined && (
              <TextField
                disabled={!isUserEditable}
                multiline
                size="small"
                name="aboutMe"
                id="aboutMe"
                value={values?.aboutMe}
                label={t('aboutMe')}
                variant="outlined"
                aria-describedby={t('aboutMe')}
                sx={{ mb: 2 }}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.aboutMe && errors.aboutMe as string}
                error={touched.aboutMe && !!errors.aboutMe}
              />
            )}
            <Grid container gap={2} justifyContent="flex-end">
              <Button
                data-testid="user-info-submit"
                disabled={isEqual(initialValues, values) || Object.keys(errors).length !== 0}
                variant="contained"
                type="submit"
              >
                {t('save')}
              </Button>
            </Grid>
          </form>
          {userId === currentUserId && <EditPasswordForm />}
        </Grid>
      </Grid>
      <Grid container mt={2} justifyContent="flex-end">
        {/* navigate('/') will redirect to base private route */}
        <Button onClick={() => navigate('/login')} variant="contained">
          {t('back')}
        </Button>
      </Grid>
    </Box>
  );
}
