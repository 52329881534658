import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from 'hooks';
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Link,
  Button,
  Grid,
  Radio,
  RadioGroup,
  FormLabel,
  MenuItem,
  Box, FormHelperText,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ROLE_URLS } from 'api/urls';
import DatePicker from 'components/shared/DatePicker';
import PhoneInput from 'components/shared/PhoneInput';
import formStyles from 'components/forms/Form.styles';
import { getAllAuthUserStore } from 'feature/auth/authSelectors';
import { requestSignUpCode } from 'feature/user/userService';
import UserProfileImage from 'components/UserProfileImage';
import { COMMUNICATION_TYPE_OPTIONS, REGISTRATION_SUB_PAGES } from 'configs';
import { RegistrationType } from 'pages/RegistrationPage/interface';
import { addUserProfileImage, deleteUserProfileImage } from 'feature/user/userSlice';
import { OutlineButton, ExtraButtonBox, ExtraButton } from 'components/shared/styles';
import SelectCountry, { getIsoCountyCodeOfThePhoneNumber } from 'components/shared/SelectCountry';
import { getPhoneRaw } from 'utils';

export default function RegistrationForm({
                                           values,
                                           errors,
                                           touched,
                                           handleBlur,
                                           handleChange,
                                           setFieldValue,
                                           validateForm,
                                           setActiveSubPage,
                                           userId,
                                           userRole,
                                           setOpenEditModal = () => {},
                                           isRegistration = true,
                                           isClientProfile = false,
}: RegistrationType) {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const { isLoading, user: { role } } = useSelector(getAllAuthUserStore);
  const [countryOfPhone, setCountryOfPhone] = useState(getIsoCountyCodeOfThePhoneNumber('UKR'));
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const navigateNext = () => {
    if (validateForm) {
      validateForm();
    }
    setShowErrors(!isEmpty(errors));
    if (!isEmpty(errors)) {
      return;
    }
    requestSignUpCode({ phone: getPhoneRaw(values.phone, countryOfPhone) })
      .then(() => {
        enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
        setActiveSubPage(REGISTRATION_SUB_PAGES.confirmCode);
      })
      .catch(() => {
        enqueueSnackbar(`${t('cannotSendSmsToThePhoneNumber')} ${t('checkThePhoneNumber')}`, { variant: 'error' });
      });
  };
  const getValidationCondition = (key: string) => ((showErrors && errors[key]) || (touched[key] && errors[key]));
  return (
    <Box>
      {isRegistration &&
      <Typography variant="h4" sx={{ mb: 3, mt: 4 }}>
        {t('createAccount')}
      </Typography>}
      <Typography
        variant="h5"
        sx={formStyles.subTitle}
      >
        {t('personalData')}
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={5.8}>
          <TextField
            id="firstName"
            name="firstName"
            label={t('firstName')}
            variant="outlined"
            sx={formStyles.input}
            fullWidth
            required
            aria-describedby={t('firstName')}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={getValidationCondition('firstName')}
            error={getValidationCondition('firstName')}
          />
        </Grid>
        <Grid item xs={12} md={5.8}>
          <TextField
            id="lastName"
            name="lastName"
            label={t('lastName')}
            variant="outlined"
            aria-describedby={t('lastName')}
            sx={formStyles.input}
            fullWidth
            helperText={getValidationCondition('lastName')}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getValidationCondition('lastName')}
            required={!isRegistration && !isClientProfile}
          />
        </Grid>
        <Grid item>
          <FormLabel sx={{ color: '#000000', fontWeight: 500 }} required>{t('gender')}</FormLabel>
          <RadioGroup
            row
            name="gender"
            sx={{ mt: 2 }}
            value={values.gender}
            onChange={handleChange}
          >
            <FormControlLabel value="Male" control={<Radio />} label={t('man')} />
            <FormControlLabel value="Female" control={<Radio />} label={t('woman')} />
            <FormControlLabel value="Other" control={<Radio />} label={t('other')} />
          </RadioGroup>
          {showErrors && <FormHelperText error>{errors.gender}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            label={t('birthday')}
            value={values.birthday}
            setFieldValue={setFieldValue}
            isValidated={showErrors}
            error={errors.birthday}
          />
        </Grid>
        <Grid item xs={12}>
          <UserProfileImage
            isViewOnly={false}
            // @ts-ignore
            imageUrl={(userRole && values.file) ? `${ROLE_URLS[userRole]}/${userId}/profile-picture` : ''}
            isClientProfile
            handler={(file: any) => {
              if (!isRegistration) {
                const formData = new FormData();
                formData.append('file', file);
                dispatch(addUserProfileImage({
                  id: userId,
                  role: userRole,
                  formData,
                }));
              }
              setFieldValue('file', file, true);
            }}
            deletePhoto={() => {
              if (!isRegistration) {
                dispatch(deleteUserProfileImage({
                  id: userId,
                  role: userRole,
                }));
              }
            }}
          />
          <Typography
            variant="h5"
            sx={formStyles.subTitle}
          >
            {t('dataForEntrance')}:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ position: 'relative' }}>
            {isRegistration && <SelectCountry liftCountry={setCountryOfPhone} sx={formStyles.input} />}
            <PhoneInput
              onBlur={handleBlur}
              FormHelperTextProps={{ style: formStyles.helper }}
              countryOfPhone={isRegistration ? countryOfPhone : 'none'}
              sx={formStyles.input}
              fullWidth
              required
              label={t('phone')}
              value={values.phone}
              id="phone"
              name="phone"
              onChange={handleChange}
              error={getValidationCondition('phone')}
              helperText={getValidationCondition('phone')}
              disabled={!isRegistration}
            />
            {!isRegistration && (role === 'Client' || role === 'Consultant') &&
            <Box sx={ExtraButtonBox}>
              <OutlineButton
                color="primary"
                size="large"
                variant="outlined"
                sx={ExtraButton}
                onClick={() => setOpenEditModal(true)}
              >
                <ModeIcon />
              </OutlineButton>
            </Box>}
          </Box>
        </Grid>
        <Grid xs={12}>
          {isRegistration && <TextField
            autoComplete="new-password"
            onBlur={handleBlur}
            type={showPassword ? 'text' : 'password'}
            FormHelperTextProps={{ sx: formStyles.helper, }}
            sx={formStyles.input}
            fullWidth
            id="password"
            name="password"
            label={t('password')}
            required
            value={values.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              getValidationCondition('password') ||
              Boolean(errors.formError)
            }
            helperText={getValidationCondition('password')}
          />}
          {isRegistration && <TextField
            autoComplete="new-password"
            onBlur={handleBlur}
            type={showConfirmPassword ? 'text' : 'password'}
            FormHelperTextProps={{ sx: formStyles.helper, }}
            sx={formStyles.input}
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label={t('repeatPassword')}
            required
            value={values.confirmPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              getValidationCondition('confirmPassword') ||
              Boolean(errors.formError)
            }
            helperText={getValidationCondition('confirmPassword')}
          />}
          <TextField
            autoComplete="new-password"
            onBlur={handleBlur}
            sx={formStyles.input}
            fullWidth
            label={t('email')}
            value={values.email}
            id="email"
            name="email"
            onChange={handleChange}
            error={
              getValidationCondition('email') ||
              Boolean(errors.formError)
            }
            helperText={getValidationCondition('email')}
          />
        </Grid>
        {(isRegistration || isClientProfile) &&
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={formStyles.subTitle}
          >
            {t('communicationChannel')}
          </Typography>
          <TextField
            autoComplete="email"
            select
            fullWidth
            sx={formStyles.input}
            name="communicationChannel"
            label={t('mailCommunicationWay')}
            variant="outlined"
            aria-describedby={t('communicationChannel')}
            helperText={getValidationCondition('communicationChannel')}
            value={values.communicationChannel}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              getValidationCondition('communicationChannel')
            }
          >
            {COMMUNICATION_TYPE_OPTIONS.map(({ label, value }: any) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>}
      </Grid>
      {isRegistration &&
      <Button
        color="primary"
        disabled={isLoading}
        size="large"
        fullWidth
        variant="contained"
        sx={{ mb: 2, mt: 2 }}
        onClick={navigateNext}
      >
        {t('create')}
      </Button>}
      {isRegistration &&
      <Typography align="center" variant="body2" color="#65656A" pt={1}>
        {t('doYouHaveAccount')}
        <Link href="/login" color="secondary" ml={1} mr={1}>{t('logIn')}</Link>
      </Typography>}
    </Box>
  );
}
