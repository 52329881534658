import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import { STATUS_TYPES } from 'configs';
import { getConfirmExpertPath, getDeclineExpertPath, getExpertInfoPath } from '../configs/routes';
import {
    BrandButton,
    CancelButton,
    ConsultantMediaStyles,
    ConsultantPictureStyles, ExpertAboutContentStyles, ExpertAboutTitleStyles, OutlineButton
} from './ExpertCard.styles';
import { ROLE_URLS } from '../api/urls';
import userIcon from '../assets/user-icon.svg';
import { getClientConsultantFromRelationsStore } from '../feature/relations/relationsSelectors';
import { ChipStyles, ChipContainerStyles, TitleStyles } from './shared/styles';

export default function ExportCard({
  consultant,
  currentUserData,
  isSelectedConsultant,
  showChangeModal
}: any) {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const clientConsultantRequests = useSelector(getClientConsultantFromRelationsStore);

    const handleDecline = () => {
      const path = getDeclineExpertPath(consultant.user.id);

      if (showChangeModal) {
        showChangeModal(() => navigate(path));
      } else {
        navigate(path);
      }
    };

    const handleConfirm = () => {
      const path = getConfirmExpertPath(consultant.user.id);
      if (showChangeModal) {
        showChangeModal(() => navigate(path));
      } else {
        navigate(path);
      }
    };

    const handleExpertDetails = () => {
      if (showChangeModal) {
        showChangeModal(() => navigate(getExpertInfoPath(consultant.user.id)));
      } else {
        navigate(getExpertInfoPath(consultant.user.id));
      }
    };

  const clientRemoveConsultantRequests = clientConsultantRequests?.data?.find((request: any) => request.type === 'ChangeConsultant' && request.status === STATUS_TYPES.new);

    return (
      <Card sx={ConsultantMediaStyles}>
        <CardMedia
          component="img"
          sx={ConsultantPictureStyles}
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          image={consultant.photoId ? `${ROLE_URLS[consultant.user.role]}/${consultant.user.id}/profile-picture` : userIcon}
          alt="Фото Консультанта"
        />
        <CardContent>
          <Typography component="h4" variant="h4">
            {consultant.firstName} {consultant.lastName}
          </Typography>

          <Typography
            sx={TitleStyles}
            variant="h6"
            color="#000"
            component="h6"
          >
            {t('specialisation')}:
          </Typography>
          <Stack
            spacing={0}
            direction="row"
            sx={ChipContainerStyles}
          >
            {consultant?.specialisations &&
            consultant.specialisations.map((skill: any) => (
              <Chip
                key={skill}
                label={skill}
                color="primary"
                style={ChipStyles}
              />
            ))}
          </Stack>
          <Box>
            <Typography
              sx={ExpertAboutTitleStyles}
              variant="h6"
              color="#000"
              component="h6"
            >
              {t('aboutConsultant')}:
            </Typography>

            <Typography paragraph sx={{ ...ExpertAboutContentStyles, display: '-webkit-box' }}>
              {consultant.aboutMe}
            </Typography>
          </Box>
          <Stack spacing={1} direction="row">
            {(currentUserData?.consultant?.user.id === consultant.user.id || isSelectedConsultant) ? (
              <CancelButton
                variant="contained"
                size="large"
                onClick={handleDecline}
                disabled={clientRemoveConsultantRequests}
              >
                <Typography
                  variant="button"
                >
                  {t('decline')}
                </Typography>
              </CancelButton>
              ) : (
                <BrandButton
                  variant="contained"
                  size="large"
                  disabled={currentUserData?.consultant && currentUserData.consultant.user.id !== consultant.user.id}
                  onClick={handleConfirm}
                >
                  <Typography
                    variant="button"
                  >
                    {t('choose')}
                  </Typography>
                </BrandButton>
              )}
            <OutlineButton
              variant="outlined"
              size="large"
              onClick={handleExpertDetails}
            >
              <Typography
                variant="button"
              >
                {t('detail')}
              </Typography>
            </OutlineButton>
          </Stack>
        </CardContent>
      </Card>
    );
}

ExportCard.propTypes = {
  consultant: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    specialisations: PropTypes.arrayOf(PropTypes.string),
    aboutMe: PropTypes.string,
    photoId: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      role: PropTypes.string
    })
  }),
  currentUserData: PropTypes.shape({
    consultant: PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.string
      })
    })
  }),
  isSelectedConsultant: PropTypes.bool,
  showChangeModal: PropTypes.func
};

ExportCard.defaultProps = {
  consultant: null,
  currentUserData: null,
  isSelectedConsultant: false,
  showChangeModal: undefined
};
