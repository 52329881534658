import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { getUserFromUserStore } from 'feature/user/userSelectors';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { requestPasswordReset } from 'feature/user/userSlice';
import formStyles from 'components/forms/Form.styles';
import PhoneInput from 'components/shared/PhoneInput';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormStepTitle, PrimaryBtn } from 'components/forms/RestorePassword/FormStepComponents';
import { createPhoneValidation } from 'pages/RegistrationPage/helper';
import SelectCountry, { getIsoCountyCodeOfThePhoneNumber } from 'components/shared/SelectCountry';
import { getPhoneRaw } from 'utils';

type StepProps = {
  moveToNextStep: Function,
  setPhone: Function,
};

export default function EnterPhoneNumFormStep({ moveToNextStep, setPhone }: StepProps) {
  const dispatch = useAppDispatch();
  const { isLoadingRequestPasswordReset } = useSelector(getUserFromUserStore);
  const { t } = useTranslation(['common']);
  const [countryOfPhone, setCountryOfPhone] = useState(getIsoCountyCodeOfThePhoneNumber('UKR'));
  const validationSchema = Yup.object().shape({
    phone: createPhoneValidation(t),
  });
  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema,
    onSubmit: async ({ phone }) => {
      const phoneRaw = getPhoneRaw(phone, countryOfPhone);
      await dispatch(requestPasswordReset(phoneRaw));
      setPhone(phoneRaw);
      moveToNextStep();
    },
  });
  return (
    <form
      style={formStyles.form}
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <FormStepTitle stepHeader={t('forgotPassword')} stepDescription={t('restorePasswordDescription')} />
      <SelectCountry liftCountry={setCountryOfPhone} sx={formStyles.input} />
      <PhoneInput
        autoFocus
        autoComplete="off"
        onBlur={formik.handleBlur}
        sx={formStyles.input}
        fullWidth
        required
        label={t('phoneNumber')}
        value={formik.values.phone}
        countryOfPhone={countryOfPhone}
        id="phone"
        name="phone"
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
      />
      <PrimaryBtn disabled={isLoadingRequestPasswordReset} btnContent={t('getCode')} />
    </form>
  );
}
EnterPhoneNumFormStep.propTypes = {
  moveToNextStep: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
};
