export const styles = {
  whiteBlock: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    p: 3
  }
};

export const header = (theme: any) => ({
  mb: '2rem',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '20px'
  },
});
