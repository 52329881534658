export const VALIDATION_ERROR_MESSAGES = {
  firstName: {
    min: 'Імʼя може містити від 2 до 32 літер',
    max: 'Імʼя може містити від 2 до 32 літер',
    required: 'Будь ласка, введіть імʼя',
  },
  lastName: {
    min: 'Прізвище може містити від 2 до 32 літер',
    max: 'Прізвище може містити від 2 до 32 літер',
    required: 'Будь ласка, введіть прізвище',
  },
  email: {
    required: 'Будь ласка, введіть Email',
    email: 'Будь ласка, введіть Email',
  },
  phone: {
    required: 'Будь ласка, введіть номер телефону',
  },
  password: {
    required: 'Будь ласка, введіть пароль',
    matches:
      'Вимоги до паролю не виконані. Пароль має містити мінімум 8 символів, 1 велику літеру і 1 цифру',
  },
  gender: { required: 'Будь ласка, виберіть стать' },
  aboutMe: { required: 'Будь ласка, введіть інформацію про себе' },
  birthday: { min: 'Виберіть дату після 1900 року', required: 'Будь ласка, виберіть дату народження' },
  violenceType: { required: 'Будь ласка, виберіть тип насильства' },
  communicationChannel: { required: 'Будь ласка, виберіть спосіб комунікації' },
  experience: { required: 'Будь ласка, напишіть кілька слів про свій досвід роботи' },
  other: { required: 'У разі вибору варіанту “Інше” це поле є обов’язковим' },
  specialisations: {
    required: 'Будь ласка, напишіть коротко на яких проблемах в роботі з клієнтами ви спеціалізуєтеся',
    max: 'Будь-ласка, оберіть тільки {{maxNumberOfSpecializations}} спеціалізацій',
  }
};
