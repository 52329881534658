import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IsoCountryCodes } from 'utils';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SxProps, Theme } from '@mui/material';

const lsKey = 'iso-country-code-of-the-phone-number';

function setIsoCountyCodeOfThePhoneNumber(isoCountryCode: IsoCountryCodes | 'none') {
  localStorage.setItem(lsKey, isoCountryCode);
}

export function getIsoCountyCodeOfThePhoneNumber(defaultCountryCode: string | null = null): IsoCountryCodes | 'none' | null {
  return (localStorage.getItem(lsKey) || defaultCountryCode) as any;
}

function SelectCountry({ liftCountry, sx }: { liftCountry: Function, sx?: SxProps<Theme> }) {
  const { t } = useTranslation(['common']);
  const [country, setCountry] = useState(getIsoCountyCodeOfThePhoneNumber('UKR'));
  useEffect(() => {
    if (typeof liftCountry === 'function') {
      liftCountry(country);
    }
  }, [country]);
  return (
    <FormControl sx={sx} fullWidth>
      <InputLabel id="select-country-phone-code" disableAnimation>{t('selectCountryPhoneCode')}</InputLabel>
      <Select
        value={country}
        onChange={(value) => {
          const isoCountyCode = (value?.target?.value as IsoCountryCodes) || 'none';
          setIsoCountyCodeOfThePhoneNumber(isoCountyCode);
          setCountry(isoCountyCode);
        }}
        fullWidth
        labelId="select-country-phone-code"
        label={t('selectCountryPhoneCode')}
        id="select-country"
        name="country"
      >
        <MenuItem value={IsoCountryCodes.UKR}>{t(`countries.${IsoCountryCodes.UKR}`)}</MenuItem>
        <MenuItem value={IsoCountryCodes.POL}>{t(`countries.${IsoCountryCodes.POL}`)}</MenuItem>
        <MenuItem value={IsoCountryCodes.ROU}>{t(`countries.${IsoCountryCodes.ROU}`)}</MenuItem>
        <MenuItem value={IsoCountryCodes.MDA}>{t(`countries.${IsoCountryCodes.MDA}`)}</MenuItem>
        <MenuItem value={IsoCountryCodes.GBR}>{t(`countries.${IsoCountryCodes.GBR}`)}</MenuItem>
        <MenuItem value={IsoCountryCodes.NLD}>{t(`countries.${IsoCountryCodes.NLD}`)}</MenuItem>
        <MenuItem value={IsoCountryCodes.DEU}>{t(`countries.${IsoCountryCodes.DEU}`)}</MenuItem>
        <MenuItem value="none">{t('differentCountry')}</MenuItem>
      </Select>
    </FormControl>
  );
}
SelectCountry.propTypes = {
  liftCountry: PropTypes.func.isRequired,
};
SelectCountry.defaultProps = {
  sx: undefined,
};

export default SelectCountry;
