export const getDateWithoutTimezone = (date: any) => {
  const newDate = new Date(date);
  const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;
  return new Date(newDate.getTime() - userTimezoneOffset);
};

export const fancyTimeFormat = (e: number) => {
  const m = Math.floor((e % 3600) / 60).toString().padStart(2, '0');
  const s = Math.floor(e % 60).toString().padStart(2, '0');

  return m.concat(':', s);
};

export const hiddenPhoneRegExp = (phone: string) => {
  if (phone.startsWith('380') && phone.length === 12) {
    return phone.replace(/(\d{3})(\d{5})(\d{3})(\d{1})/, '+$1-$2***$4');
  }
  if (phone.length < 7) {
    return `+${phone}`;
  }
/* eslint prefer-template: 0 */
return '+' + phone.substring(0, 4) +
  phone.substring(4, phone.length - 2).replace(/./g, '*') +
  phone.substring(phone.length - 2, phone.length);
};
