import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import Cookies from 'js-cookie';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useSaveChangesDialogOnExit } from 'hooks/useSaveChangesDialogOnExit';
import RegistrationForm from 'components/forms/RegistrationForm';
import Spinner from 'components/shared/Spinner';
import formStyles from 'components/forms/Form.styles';
import { DeleteButton, OutlineButton } from 'components/shared/styles';
import SpecializationInput from 'components/shared/SpecialisationInput';
import ConfirmationDialogAfterChanges from 'components/modals/ConfirmationDialogAfterChanges';
import UpdatePhoneDialog from 'components/modals/UpdatePhoneModal';
import RightTitle from 'components/EditUser/RightTitle';
import { validationSchema } from 'pages/RegistrationPage/helper';
import { logout } from 'feature/auth/authSlice';
import { getConsultantUserProfile, updateConsultantUserProfile } from 'feature/user/userSlice';
import { getUserFromUserStore } from 'feature/user/userSelectors';
import { CONSULTANT_ACCOUNT_DEACTIVATED_SUCCESSFULLY_PATH } from 'utils';
import SoftDeleteUserAccount from 'components/modals/SoftDeleteUserProfile';
import { styles, header } from './styles';

function isUkrainian(value: string): Boolean {
  return (/^[а-щьюяґєії' -]+$/iug).test(value);
}

export default function ConsultantProfile({ isAdmin = false }: {isAdmin?: boolean}) {
  const { userId, userRole } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [softDeleteIsOpen, setSoftDeleteIsOpen] = useState(false);
  const { t } = useTranslation(['common']);
  const [saveChangesDialogIsOpen, setSaveChangeDialogIsOpen] = useState(false);
  const [isEditModalOpen, setOpenEditModal] = useState(false);
  const [validation, setValidation] = React.useState(validationSchema(t, false, false));
  const [wasBackButtonClicked, setBackButtonClicked] = useState(false);
  const [activeCb, setActiveCb] = useState(() => () => {});
  const currentUserId = Cookies.get('id');

  const isYou = userId === currentUserId;

  useEffect(() => {
    dispatch(getConsultantUserProfile(userId as string));
  }, [userId, dispatch]);

  const { isLoading, user: consultantDetail } = useAppSelector(getUserFromUserStore);

  const handleBackNavigation = () => {
    if (wasBackButtonClicked) {
      if (isAdmin) {
        navigate('/admin/users');
      } else {
        navigate('/consultant/client-list');
      }
      setBackButtonClicked(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: consultantDetail?.firstName || '',
      lastName: consultantDetail?.lastName || '',
      gender: consultantDetail?.gender || '',
      birthday: consultantDetail?.birthday || '',
      phone: consultantDetail?.user?.phone || '',
      email: consultantDetail?.user?.email || '',
      file: consultantDetail?.photoId || '',
      specialisations: consultantDetail?.specialisations || [],
      experience: consultantDetail?.experience || '',
      aboutMe: consultantDetail?.aboutMe || ''
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (data) => {
      dispatch(updateConsultantUserProfile({
        id: userId,
        role: userRole,
        isAdmin,
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          birthday: data.birthday,
          gender: data.gender,
          aboutMe: data.aboutMe,
          experience: data.experience,
          specialisations: data.specialisations,
          user: {
            email: data.email,
            phone: data.phone
          }
      } }));
      handleBackNavigation();
    }
  });

  const {
    values,
    initialValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  useSaveChangesDialogOnExit(initialValues, values, setSaveChangeDialogIsOpen, setBackButtonClicked);

  const navigateToList = () => {
    navigate('/consultant/client-list');
  };

  const showChangeModal = (cb: Function) => {
    if (!isEqual(initialValues, values)) {
      setSaveChangeDialogIsOpen(true);
      setActiveCb(() => cb);
    } else {
      cb();
    }
  };

  function getSpecializationErrorMsg() {
    const valuesToCheck: string[] = (values?.specialisations || []);
    const areAllSpecializationsInUkrainian = valuesToCheck.filter((name) => !isUkrainian(name)).length === 0;
    if (!areAllSpecializationsInUkrainian) {
      return t('wrongSpecialisationCharacters');
    }
    const maxLength = 35;
    const tooLongOption = !!valuesToCheck.find((name) => name.length > maxLength);
    if (tooLongOption) {
      return t('specializationNameCannotBeLongerThan', { maxLength });
    }
    if (typeof errors.specialisations === 'string' && errors.specialisations.length > 0) {
      return errors.specialisations;
    }
    return '';
  }
  const specializationErrorMsg = getSpecializationErrorMsg();
  return (
    isEmpty(consultantDetail) ? (
      <Spinner />
    ) : (
      <>
        {!isYou &&
        <Grid container justifyContent="flex-end">
          <RightTitle
            isYou={isYou}
            id={consultantDetail.user.externalId || ''}
            createdAt={isYou ? '' : consultantDetail.user.createdAt}
            lastLoginAt={isYou ? '' : consultantDetail.user.lastLoginAt}
          />
        </Grid>}
        <Grid container spacing={2} sx={{ justifyContent: 'center', mt: isYou ? '3rem' : '0' }}>
          <Grid xs={12} sm={10} md={isAdmin ? 10 : 8} lg={isAdmin ? 9 : 7}>
            <Grid container spacing={2}>
              <Typography sx={header} variant="h4" component="h1">
                {isAdmin ? t('profile') : t('myProfile')}
              </Typography>
              {isLoading ?
                <Spinner /> :
                <Box sx={styles.whiteBlock}>
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={11} md={9}>
                      <RegistrationForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setValidation={setValidation}
                        setActiveSubPage={() => {}}
                        isRegistration={false}
                        userId={userId}
                        userRole={userRole || 'Consultant'}
                        setOpenEditModal={setOpenEditModal}
                      />
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          sx={formStyles.subTitle}
                        >
                          {t('professionalData')}
                        </Typography>
                        <SpecializationInput
                          specialisations={values?.specialisations}
                          handleChange={(value: string[]) => {
                            setFieldValue('specialisations', value, true);
                          }}
                          error={specializationErrorMsg}
                        />
                        <TextField
                          multiline
                          size="small"
                          fullWidth
                          name="experience"
                          id="experience"
                          value={values?.experience}
                          label={t('professionAndAccreditation')}
                          variant="outlined"
                          aria-describedby={t('experience')}
                          sx={{ mb: 2 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.experience && errors.experience as string}
                          error={touched.experience && !!errors.experience}
                        />
                        <TextField
                          multiline
                          size="small"
                          fullWidth
                          name="aboutMe"
                          id="aboutMe"
                          value={values?.aboutMe}
                          label={t('experience')}
                          variant="outlined"
                          aria-describedby={t('aboutMe')}
                          sx={{ mb: 2 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.aboutMe && errors.aboutMe as string}
                          error={touched.aboutMe && !!errors.aboutMe}
                        />
                      </Grid>
                      {!isAdmin &&
                      <Typography
                        variant="h5"
                        sx={formStyles.subTitle}
                      >
                        {t('yourClients')}
                      </Typography>}
                      {!isAdmin &&
                      <OutlineButton
                        color="primary"
                        size="large"
                        variant="outlined"
                        sx={{ mb: 2, mt: 2 }}
                        onClick={() => showChangeModal(() => navigateToList())}
                      >
                        {t('clientList')}
                      </OutlineButton>}
                    </Grid>
                    <Typography
                      variant="h5"
                      sx={formStyles.subTitle}
                    >
                      {t('options')}
                    </Typography>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isEqual(initialValues, values) || Object.keys(errors).length !== 0 || !!specializationErrorMsg}
                      size="large"
                      variant="contained"
                      sx={{ mr: 2, mb: 2 }}
                    >
                      {t('saveChanges')}
                    </Button>
                    {!isAdmin &&
                    <OutlineButton
                      color="primary"
                      size="large"
                      variant="outlined"
                      sx={{ mr: 2, mb: 2 }}
                      onClick={() => showChangeModal(() => dispatch(logout()))}
                    >
                      {t('logOut')}
                    </OutlineButton>}
                    {!isAdmin &&
                    <DeleteButton
                      color="primary"
                      size="large"
                      variant="contained"
                      sx={{ mr: 2, mb: 2 }}
                      onClick={() => setSoftDeleteIsOpen(true)}
                    >
                      {t('deleteAccount')}
                    </DeleteButton>}
                  </form>
                </Box>}
            </Grid>
          </Grid>
          {isAdmin &&
          <Grid container mt={2} justifyContent="flex-end">
            <Button onClick={() => showChangeModal(() => navigate('/admin/users'))} size="large" variant="contained">
              {t('back')}
            </Button>
          </Grid>}
        </Grid>
        <SoftDeleteUserAccount
          userId={userId}
          open={softDeleteIsOpen}
          navigateTo={CONSULTANT_ACCOUNT_DEACTIVATED_SUCCESSFULLY_PATH}
          deleteBtnText={t('sendRequest')}
          closeHandler={() => setSoftDeleteIsOpen(false)}
        />
        <ConfirmationDialogAfterChanges
          open={saveChangesDialogIsOpen}
          okHandler={() => {
            handleSubmit();
            setSaveChangeDialogIsOpen(false);
            activeCb();
          }}
          closeHandler={() => setSaveChangeDialogIsOpen(false)}
          notSave={() => {
            setSaveChangeDialogIsOpen(false);
            handleBackNavigation();
            activeCb();
          }}
        />
        <UpdatePhoneDialog
          open={isEditModalOpen}
          closeHandler={() => setOpenEditModal(false)}
          okHandler={(value) => setFieldValue('phone', value)}
          userId={userId}
        />
      </>
    )
  );
}

ConsultantProfile.defaultProps = {
  isAdmin: false,
};
