const Container = {
  body: {
    margin: '0 !important'
  }
};
const HeaderBox = (theme: any) => ({
  backgroundColor: '#8d7db6',
  padding: '20px',
  height: '38vh',
  display: 'flex',
  alignItems: 'center'
});
const CenterContent = {
  margin: '0 auto',
  width: '75%',
};

const ScreenHeight = {
  maxHeight: '100vh'
};
const CenterBox = (matches: boolean) => ({
  paddingTop: '10px',
  margin: '0 auto',
  width: matches ? '75%' : '90%',
  justifyContent: 'space-between',
  height: '100%',
  alignItems: 'center'
});

const HeaderText = (matches: boolean) => ({
  fontFamily: 'Manrope, sans-serif',
  fontWeight: 800,
  color: '#fff',
  paddingBottom: '10px',
  paddingLeft: !matches && '18px',
  textAlign: matches ? 'none' : 'center',
});

const BorderRadius = {
  borderRadius: '12px',
  overflow: 'hidden',
  height: '100%'
};
const QuestionSection = (theme: any) => ({
  backgroundColor: '#F3E9FF',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  p: '20px 20px 20px 30px',

  h4: {
    [theme.breakpoints.down('xl')]: {
      fontSize: '28px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px'
    }
  }
});
const ButtonStyles = {
  color: '#F9F7FB',
  textDecoration: 'none',
  fontFamily: 'Manrope, sans-serif',
  lineHeight: '20px',
  padding: '10px 16px',
};
const BackgroundPurple = {
  backgroundColor: '#7969A1',
  '&:hover': {
    backgroundColor: '#7969A1',
  }
};
const BackgroundOrange = {
  backgroundColor: '#A97C69',
  '&:hover': {
    backgroundColor: '#A97C69',
  }
};
const DividerBox = {
  padding: '0',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  p: '20px 0'
};
const InfoTextTitle = (matches: boolean) => (theme: any) => ({
  fontWeight: 800,
  fontFamily: 'Manrope, sans-serif',
  color: '#A97C69',
  paddingBottom: '16px',
  paddingLeft: '0',
  textAlign: matches ? 'none' : 'center',

  [theme.breakpoints.down('lg')]: {
    fontSize: '28px',
  },
});
const InfoText = (matches: boolean) => (theme: any) => ({
  fontWeight: 400,
  fontFamily: 'Manrope, sans-serif',
  fontSize: '20px',
  color: '#A97C69',
  lineHeight: '27px',
  paddingLeft: '0',
  textAlign: matches ? 'none' : 'center',

  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
  },
});
const DescriptionSection = {
  padding: '16px',
  background: '#F9F5FF',
  margin: '20px 0',
  textAlign: 'center'
};
const DescriptionTitleSection = (matches: boolean) => ({
  fontWeight: 800,
  fontFamily: 'Manrope, sans-serif',
  fontSize: matches ? '32px' : '24px',
  color: '#7969A1',
  lineHeight: '38px',
  textAlign: matches ? 'none' : 'center',
});
const DescriptionTitle = (theme: any) => ({
  fontWeight: 600,
  fontFamily: 'Manrope, sans-serif',
  color: '#7969A1',
  lineHeight: '22px',

  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    lineHeight: '18px'
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    lineHeight: '15px'
  }
});
const LastDivider = {
  paddingBottom: 0
};
const ConsultantTitleInfo = {
  fontWeight: 500,
  fontFamily: 'Manrope, sans-serif',
  color: '#7969A1',
  lineHeight: '22px',
  paddingLeft: '10px',
  paddingTop: '15px'
};
const ConsultantInfo = {
  fontWeight: 600,
  fontFamily: 'Manrope, sans-serif',
  color: '#7969A1',
  padding: '5px 10px'
};

const ConsultantText = {
  fontWeight: 400,
  fontFamily: 'Manrope, sans-serif',
  color: '#7969A1',
  padding: '0 10px 10px'
};
const ConsultantSpan = (matches: boolean) => ({
  fontWeight: 400,
  fontFamily: 'Manrope, sans-serif',
  fontSize: matches ? '24px' : '18px',
  color: '#7969A1',
  lineHeight: matches ? '44px' : '35px',
  paddingBottom: '24px',
  textAlign: 'center'
});
const ConsultantInfoSection = {
  padding: '64px 0'
};
const ConsultantSection = {
  padding: '64px 0',
  backgroundColor: '#F3E9FF'
};
const ConsultantDescription = {
  background: '#FFF',
  borderTopRightRadius: '12px',
  borderBottomRightRadius: '12px',
};
const ConsultantImg = (theme: any) => ({
  height: '100%',
  minHeight: '175px',
  maxHeight: '100%',
  borderTopLeftRadius: '12px',
  borderBottomLeftRadius: '12px',

  [theme.breakpoints.down('md')]: {
    maxHeight: '250px',
  }
});
const FooterTitle = {
  fontWeight: 400,
  fontSize: '12px',
  color: '#fff',
  display: 'flex',
  gap: '10px'
};
const FooterSection = {
  backgroundColor: '#8D7DB6',
  paddingTop: '102px',
  paddingBottom: '50px'
};
const DividerStyles = {
  width: '100%',
  span: {
    padding: '0 40px',
  },
  '&::before, &::after': {
    top: '-3%',
  },
  '.MuiDivider-wrapper': {
    paddingLeft: 'calc(8px * 8)',
    paddingRight: 'calc(8px * 8)'
  }
};
const DividerPurple = {
  '&::before, &::after': {
    borderColor: '#CFBDFA',
  },
};
const DividerOrange = {
  '&::before, &::after': {
    borderColor: '#FFD2BD',
  },
};
const LinkStyles = {
  marginLeft: '28px',
  textAlign: 'end'
};

const BtnCentered = (theme: any) => ({
  textDecoration: 'none',

  [theme.breakpoints.down('md')]: {
    margin: '0 auto'
  }
});
const GlyphBox = (theme: any) => ({
  display: 'flex',
  backgroundColor: '#FCECE4',
  borderRadius: '12px',
  alignItems: 'center',
  justifyContent: 'center',
  height: '130px',
  width: '130px',

  [theme.breakpoints.down('lg')]: {
    height: '100px',
    width: '100px',
  },

  [theme.breakpoints.down('sm')]: {
    margin: '0 auto'
  },
});
const ToolbarStyles = {
  backgroundColor: '#FFF',
  justifyContent: 'space-between',
};
const ToolbarButtonStyles = {
  backgroundColor: '#A97C69',
  padding: '10px 16px',
  color: '#FFF',
  fontWeight: 700,
  fontFamily: 'Manrope, sans-serif',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#A97C69',
  }
};

const IconBlock = (matches: boolean) => ({
  width: 'auto',
  float: matches ? 'right' : 'left'
});

const Text = () => ({
  color: '#ffffff',
  fontSize: '10px',
  width: '130px',
  pb: '10px'
});

export {
  Container,
  BorderRadius,
  DividerBox,
  HeaderBox,
  HeaderText,
  CenterBox,
  CenterContent,
  QuestionSection,
  ButtonStyles,
  InfoText,
  InfoTextTitle,
  BackgroundPurple,
  BackgroundOrange,
  DescriptionSection,
  DescriptionTitleSection,
  LastDivider,
  ConsultantInfo,
  ConsultantTitleInfo,
  ConsultantInfoSection,
  FooterTitle,
  FooterSection,
  ConsultantSpan,
  ConsultantSection,
  ConsultantDescription,
  ConsultantImg,
  DividerStyles,
  DividerPurple,
  DividerOrange,
  LinkStyles,
  GlyphBox,
  ToolbarStyles,
  ToolbarButtonStyles,
  DescriptionTitle,
  BtnCentered,
  ScreenHeight,
  ConsultantText,
  IconBlock,
  Text
};
