import * as Yup from 'yup';
import { VALIDATION_ERROR_MESSAGES } from 'configs/validation/errorMessages';
import { PASSWORD_REGEX } from 'configs/validation/regex';

const maxNumberOfSpecializations = 20;

export const createPhoneValidation = (t: Function) => Yup.string()
  .required(VALIDATION_ERROR_MESSAGES.phone.required)
  .min(7, t('phoneNumberMinLengthError', { phoneNumberMinLength: 7 }))
  .max(15, t('phoneNumberMaxLengthError', { phoneNumberMaxLength: 15 }));

export const validationSchema = (t: any, isRegistration: boolean, isClientProfile: boolean) => Yup.object().shape({
  firstName: Yup
    .string()
    .min(2, VALIDATION_ERROR_MESSAGES.firstName.min)
    .max(32, VALIDATION_ERROR_MESSAGES.firstName.max)
    .required(VALIDATION_ERROR_MESSAGES.firstName.required),
  lastName: Yup.lazy(() => {
    if (isRegistration || isClientProfile) {
      return Yup.string()
        .min(2, VALIDATION_ERROR_MESSAGES.lastName.min)
        .max(32, VALIDATION_ERROR_MESSAGES.lastName.max);
    }
    return Yup.string()
      .min(2, VALIDATION_ERROR_MESSAGES.lastName.min)
      .max(32, VALIDATION_ERROR_MESSAGES.lastName.max)
      .required(VALIDATION_ERROR_MESSAGES.lastName.required);
  }),
  birthday: Yup.date()
    .min('1900/01/01', VALIDATION_ERROR_MESSAGES.birthday.min)
    .required(VALIDATION_ERROR_MESSAGES.birthday.required),
  phone: createPhoneValidation(t),
  gender: Yup.string().required(VALIDATION_ERROR_MESSAGES.gender.required),
  password: Yup.lazy(() => {
    if (isRegistration) {
      return Yup.string()
        .required(VALIDATION_ERROR_MESSAGES.password.required)
        .matches(PASSWORD_REGEX, VALIDATION_ERROR_MESSAGES.password.matches);
    }
    return Yup.mixed().notRequired();
  }),
  confirmPassword: Yup.lazy(() => {
    if (isRegistration) {
      return Yup.string()
        .required(VALIDATION_ERROR_MESSAGES.password.required)
        .matches(PASSWORD_REGEX, VALIDATION_ERROR_MESSAGES.password.matches)
        .oneOf([Yup.ref('password'), null], t('passwordShouldMatch'));
    }
    return Yup.mixed().notRequired();
  }),
  other: Yup.string().when('violenceTypes', (value) => {
    if (isRegistration && value.includes('Other')) {
      return Yup.string()
        .required(VALIDATION_ERROR_MESSAGES.other.required);
    }
    return Yup.mixed().notRequired();
  }),
  aboutMe: Yup.lazy(() => {
    if (!isRegistration && !isClientProfile) {
      return Yup
        .string()
        .required(VALIDATION_ERROR_MESSAGES.aboutMe.required);
    }
    return Yup.mixed().notRequired();
  }),
  experience: Yup.lazy(() => {
    if (!isRegistration && !isClientProfile) {
      return Yup
        .string()
        .required(VALIDATION_ERROR_MESSAGES.experience.required);
    }
    return Yup.mixed().notRequired();
  }),
  specialisations: Yup.lazy(() => {
    if (!isRegistration && !isClientProfile) {
      return Yup
        .array().of(Yup.string())
        .max(maxNumberOfSpecializations, VALIDATION_ERROR_MESSAGES.specialisations.max
          .replace('{{maxNumberOfSpecializations}}', `${maxNumberOfSpecializations}`))
        .min(1, VALIDATION_ERROR_MESSAGES.specialisations.required);
    }
    return Yup.mixed().notRequired();
  }),
  email: Yup.string().when('communicationChannel', {
    is: 'Email',
    then: Yup
      .string()
      .email(VALIDATION_ERROR_MESSAGES.email.email)
      .required(VALIDATION_ERROR_MESSAGES.email.required),
    otherwise: Yup.string().email(VALIDATION_ERROR_MESSAGES.email.email)
  }),
  additionalCommunicationContact: Yup.string().when('additionalCommunicationChannel', (value) => {
    if (value === 'Email') {
      return Yup.string()
        .email(VALIDATION_ERROR_MESSAGES.email.email)
        .required(VALIDATION_ERROR_MESSAGES.email.required);
    }
    if (value === 'Phone' || value === 'Viber' || value === 'WhatsApp' || value === 'Telegram') {
      return createPhoneValidation(t);
    }
    return Yup.mixed().notRequired();
  })
});
